export function CalenderDuoIcon({
  className,
  color = "currentColor",
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          "--icon-color": color,
          "--icon-color-dark": `color-mix(in srgb, var(--icon-color) 35%, black)`,
        } as React.CSSProperties
      }
    >
      <g clipPath="url(#clip0_12159_65181)">
        <rect
          x="1.75"
          y="3.75"
          width="20.5"
          height="18.5"
          rx="4.25"
          fill="var(--icon-color-dark)"
          stroke="var(--icon-color)"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2C5 1.44772 5.44772 1 6 1C6.55228 1 7 1.44772 7 2V3H17V2C17 1.44772 17.4477 1 18 1C18.5523 1 19 1.44772 19 2V3C19 3.03342 18.9984 3.06646 18.9952 3.09904C21.2798 3.56043 23 5.57933 23 8H1C1 5.57933 2.72019 3.56044 5.00484 3.09904C5.00164 3.06646 5 3.03342 5 3V2Z"
          fill="var(--icon-color)"
        />
        <path
          d="M9.078 16.936H11.37V13.072H9.21V11.668H9.846C11.058 11.668 11.598 11.152 11.61 10H13.134V16.936H15.102V18.52H9.078V16.936Z"
          fill="var(--icon-color)"
        />
        <path
          d="M8.24034 29.24C8.87634 29.252 9.39234 28.94 9.39234 28.256C9.39234 27.74 8.99634 27.392 8.37234 27.392C7.78434 27.392 7.36434 27.716 7.30434 28.184L5.43234 28.112C5.58834 26.72 6.75234 25.808 8.38434 25.808C10.1243 25.808 11.2403 26.732 11.2403 28.076C11.2403 28.988 10.6523 29.636 9.70434 29.912C10.8923 30.2 11.5283 31.136 11.5283 32.096C11.5283 33.632 10.2443 34.712 8.38434 34.712C6.74034 34.712 5.30034 33.776 5.22834 32.072L7.08834 32C7.13634 32.72 7.73634 33.128 8.38434 33.128C9.16434 33.128 9.68034 32.684 9.68034 32.012C9.68034 31.22 9.12834 30.716 8.24034 30.74L7.76034 30.752V29.228L8.24034 29.24Z"
          fill="var(--icon-color)"
        />
        <path
          d="M12.6757 32.936H14.9677V29.072H12.8077V27.668H13.4437C14.6557 27.668 15.1957 27.152 15.2077 26H16.7317V32.936H18.6997V34.52H12.6757V32.936Z"
          fill="var(--icon-color)"
        />
        <path
          d="M5.36034 44.736C5.44434 43.056 6.65634 41.808 8.50434 41.808C10.2923 41.808 11.5523 42.936 11.5523 44.46C11.5523 45.648 10.7243 46.548 9.64434 47.112C9.03234 47.424 7.64034 48.228 7.44834 48.936H11.5643V50.52H5.21634C5.21634 48.036 6.16434 47.148 8.22834 45.912C9.00834 45.444 9.69234 45.06 9.69234 44.436C9.69234 43.848 9.21234 43.392 8.50434 43.392C7.73634 43.392 7.26834 44.004 7.20834 44.868L5.36034 44.736Z"
          fill="var(--icon-color)"
        />
        <path
          d="M15.5797 50.712C13.6117 50.712 12.4357 48.912 12.4357 46.272C12.4357 43.632 13.6117 41.808 15.5797 41.808C17.5477 41.808 18.7237 43.632 18.7237 46.272C18.7237 48.912 17.5477 50.712 15.5797 50.712ZM14.2957 46.272C14.2957 46.716 14.3197 47.124 14.3797 47.472L16.2637 43.704C16.0717 43.5 15.8437 43.392 15.5797 43.392C14.7517 43.392 14.2957 44.4 14.2957 46.272ZM14.8597 48.78C15.0517 49.008 15.2917 49.128 15.5797 49.128C16.4077 49.128 16.8637 48.132 16.8637 46.272C16.8637 45.768 16.8277 45.336 16.7677 44.964L14.8597 48.78Z"
          fill="var(--icon-color)"
        />
        <path
          d="M5.48034 64.936H7.77234V61.072H5.61234V59.668H6.24834C7.46034 59.668 8.00034 59.152 8.01234 58H9.53634V64.936H11.5043V66.52H5.48034V64.936Z"
          fill="var(--icon-color)"
        />
        <path
          d="M18.3277 58V59.584H14.7997L14.5837 61.204C14.9197 60.916 15.3877 60.76 15.9037 60.76C17.5597 60.76 18.7837 62.008 18.7837 63.736C18.7837 65.5 17.4757 66.712 15.6037 66.712C13.8997 66.712 12.5677 65.644 12.4477 64.156L14.2957 64.084C14.3437 64.696 14.8957 65.128 15.6397 65.128C16.3717 65.128 16.9357 64.552 16.9357 63.736C16.9357 62.932 16.3357 62.308 15.5677 62.308C14.9677 62.308 14.5237 62.656 14.3557 63.052H12.5557L13.2517 58H18.3277Z"
          fill="var(--icon-color)"
        />
        <path
          d="M9.078 80.936H11.37V77.072H9.21V75.668H9.846C11.058 75.668 11.598 75.152 11.61 74H13.134V80.936H15.102V82.52H9.078V80.936Z"
          fill="var(--icon-color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_12159_65181">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
